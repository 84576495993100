









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface ILabOscillationInput {
  kio3Purity: number;
  malonicAcidPurity: number;
}

export default defineComponent({
  name: 'LabOscillation',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    kio3Purity: {
      type: Number as PropType<number>,
      default: 100,
    },
    malonicAcidPurity: {
      type: Number as PropType<number>,
      default: 100,
    },
  },
  computed: {
    labData(): ILabOscillationInput {
      return {
        kio3Purity: this.kio3Purity,
        malonicAcidPurity: this.malonicAcidPurity,
      };
    },
    stringifiedLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
