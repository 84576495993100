<template>
  <lab-task>
    <lab-oscillation :kio3-purity="kio3Purity" :malonic-acid-purity="malonicAcidPurity"
  /></lab-task>
</template>

<script>
import LabOscillation from '../simulations/LabOscillation';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';

export default defineComponent({
  name: 'OscillationSIM',
  components: {LabOscillation, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    kio3Purity() {
      return this.taskState.getValueBySymbol('kio3Purity').content.toFloat();
    },
    malonicAcidPurity() {
      return this.taskState.getValueBySymbol('malonicAcidPurity').content.toFloat();
    },
  },
});
</script>
